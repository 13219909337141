import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Card, Grid, Reveal } from 'semantic-ui-react'
import { theme } from '../components/Elements/theme'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroSmall from '../components/HeroSmall'
import CTASection from '../components/CTA Section'
import { Header, Segment, Spacer } from '../components/Elements'

const Image = styled(Img)`
  width: 290px;
`
const RowWrapper = styled(Grid.Row)`
  padding-top: 2rem;
  @media (max-width: ${theme.breakpoints.xxl}),
    (max-device-width: ${theme.breakpoints.xxl}) {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  @media (max-width: ${theme.breakpoints.l}),
    (max-device-width: ${theme.breakpoints.l}) {
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }
  @media (max-width: ${theme.breakpoints.m}),
    (max-device-width: ${theme.breakpoints.m}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: ${theme.breakpoints.xs}),
    (max-device-width: ${theme.breakpoints.xs}) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`
const CardWrapper = styled.div`
  padding: 1rem;
`

const KadraPage = ({ location }) => (
  <StaticQuery
  query={graphql`
    query {
      datoCmsHeadersSection(name: { eq: "kadra" }) {
        id
        name
        header
        heroImage {
          fixed(width: 900, height: 460) {
          ...GatsbyDatoCmsFixed
          }
          fluid(maxWidth: 1200, maxHeight: 460) {
          ...GatsbyDatoCmsFluid
          }
        }
      }
      allDatoCmsKadra(sort: {fields: [order], order: ASC} ) {
        edges {
          node {
            header
            kadra {
              name
              uid
              positionId
              imageFormal {
                fluid(imgixParams: {fit: "crop", w: "290", h: "400", q: 75, auto: "enhance"}) {
                ...GatsbyDatoCmsFluid
                }
              }
              imageCasual {
                fluid(imgixParams: {fit: "crop", w: "290", h: "400", q: 75, auto: "enhance"}) {
                ...GatsbyDatoCmsFluid
                }
              }
            }
          }
        }
      }
    }
  `}
  render={data => (
  <Layout>
    <SEO title={ data.datoCmsHeadersSection.name } />
    <HeroSmall data={ data.datoCmsHeadersSection } />
    <Grid centered  stackable>    
      { data.allDatoCmsKadra.edges.map(( item, index ) => {
        return (
          <>
            <Segment basic padded='very'>
              <Header title='true' dividing>{ item.node.header }</Header>
            </Segment>
            <RowWrapper columns='4'>
              { item.node.kadra.map(( member, index ) => {
                const { imageCasual, imageFormal, name, positionId, uid } = member
                return (
                <CardWrapper key={ index }>
                  <Card color='yellow' href={ `${location.href}/${uid}` }>
                    <Reveal animated='move' instant>
                      <Reveal.Content visible>
                        <Image fluid={ imageFormal.fluid } />
                      </Reveal.Content>
                      <Reveal.Content hidden>
                        <Image fluid={ imageCasual.fluid } />
                      </Reveal.Content>
                    </Reveal>
                    <Card.Content>
                      <Card.Header>{ name }</Card.Header>
                      <Card.Meta 
                      style={{
                        fontFamily: 'Playfair Display',
                        minHeight: '3rem'
                      }}>{ positionId }</Card.Meta>
                    </Card.Content>
                  </Card>
                </CardWrapper>
              )})}
            </RowWrapper>
          </>
        )
      })}
    </Grid>
    <Spacer size='15vh'/>
    <CTASection />
  </Layout>
  )}
/>
)

export default KadraPage
